import http from "../../helpers/http-common";

class SimulatorAccessDataService {

    login(data) {
        return http.post("/simulators/login", data);
    }
}

export default new SimulatorAccessDataService();
