<template>
    <div>
        <div class="row section-header" >
            <div class="col-md-9" style="padding-left: 65px">
                <h4>Accesso al simulatore</h4>
            </div>

            <div class="col-md-3 text-right" style="">

            </div>
        </div>
        <br>
        <div class="response-message">
            <h3 v-if="!error" class="text-success text-center">
                <b-icon icon="check-circle"/>
                <br>
                {{ title }}
            </h3>
            <h3 v-else class="text-danger text-center">
                <b-icon icon="exclamation-circle"/>
                <br>
                {{ title }}
            </h3>
            <p class="text-center">{{ message }}</p>
        </div>
    </div>

</template>

<script>
import SimulatorAccessDataService from "@/components/simulator_access/SimulatorAccessDataService";

export default {
    name: "simulator-access",
    data() {
        return {
            simulatorAccess: {
                simulatorId: null,
            },
            title: '',
            message: null,
            error: false
        };
    },
    methods: {
        accessSimulator() {
            SimulatorAccessDataService.login(this.simulatorAccess)
                .then(response => {
                    console.log(response)
                    if(response.data) {
                        this.title = 'Accesso al simulatore eseguito';
                        this.message = 'Attendi qualche secondo che venga sbloccato il simulatore';
                        this.error = false;
                    } else {
                        this.title = 'Hai già eseguito l\'accesso per questo simulatore';
                        this.error = '';
                    }
                })
                .catch(e => {
                    this.title = 'Non puoi accedere al simulatore';
                    this.message = 'errore';
                    this.error = true;

                    console.log(e);

                });
        },
    },
    mounted() {
        // get info passed bt ad profileId and set item
        this.simulatorAccess.simulatorId = this.$route.query.simulatorId;
        this.accessSimulator();
    }
};
</script>

<style scoped>
.response-message {
    padding: 40px;
    background-color: #fff;
}
</style>
